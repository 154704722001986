<i18n>
{
  "de": {
    "nameTitle": "Jahr",
    "initialYear": "Initial",
    "addButton": "Jahr hinzufügen",
    "editButton": "Bearbeiten",
    "cancelButton": "Abbrechen",
    "saveButton": "Speichern",
    "yearAlreadyExistsError": "Jahr bereits vorhanden",
    "yearInvalidError": "Jahr muss zwischen {minYear} und {maxYear} liegen",
    "pendingLabel": "Wird gespeichert...",
    "deleteConfirmation": "Wollen Sie dieses Jahr wirklich löschen?"
  }
}
</i18n>

<template>
  <div class="edit-sustainability-years">
    <DetailList v-if="sustainabilityYears" has-header class="years-table">
      <template #header>
        <span>{{ $t('nameTitle') }}</span>
        <span></span>
      </template>

      <div v-if="error">
        {{ error }}
      </div>

      <li v-for="(year, index) in sustainabilityYears" :key="index" class="row--year">
        <span v-if="editYear && editYear.id === year.id">
          <NumericInput
            v-model="yearModel"
            :min-length="4"
            :min="minYear"
            :max="maxYear"
            int
            no-formatting
            :invalid-values="invalidValues"
            :invalid-values-error-message="$t('yearAlreadyExistsError')"
            :edit="true"
            @validation="onValidation($event)"
          />
        </span>
        <span v-else>{{ year.year ? year.year : $t('initialYear') }}</span>

        <ListButtonWrapper v-if="editYear && editYear.id === year.id">
          <ListSaveButton :disabled="!isValid" @click="onEditSave" />
          <ListButton :img-src="'/icons/x-circle.svg'" :label="$t('cancelButton')" @click="onEditCancel" />
        </ListButtonWrapper>
        <ListButtonWrapper v-else-if="year.year !== 0">
          <ListEditButton
            v-if="getPortfolioPermission('EDIT_SUSTAINABILITY_YEARS') && !editYear && !addingYear"
            @click="onEditYear(year)"
          />
          <ListDeleteButton
            v-if="getPortfolioPermission('EDIT_SUSTAINABILITY_YEARS') && !editYear && !addingYear"
            @click="onDeleteYear(year)"
          />
        </ListButtonWrapper>
      </li>

      <li v-if="addingYear" class="row--year">
        <NumericInput
          v-model="yearModel"
          :min-length="4"
          :min="minYear"
          :max="maxYear"
          int
          no-formatting
          :invalid-values="invalidValues"
          :invalid-values-error-message="$t('yearAlreadyExistsError')"
          :edit="!pending"
          @validation="onValidation($event)"
        />
        <span v-if="pending">
          {{ $t('pendingLabel') }}
        </span>
        <ListButtonWrapper v-else>
          <ListSaveButton :disabled="!isValid" @click="onAddSave()" />
          <ListButton :img-src="'/icons/x-circle.svg'" :label="$t('cancelButton')" @click="onAddCancel" />
        </ListButtonWrapper>
      </li>
    </DetailList>

    <ButtonWrapper class="add-year-button">
      <button
        v-if="getPortfolioPermission('EDIT_SUSTAINABILITY_YEARS') && !editYear && !addingYear"
        type="button"
        class="button"
        @click="onAddYear()"
      >
        {{ $t('addButton') }}
      </button>
    </ButtonWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListButton from '@/components/shared/lists/ListButton.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ListSaveButton from '@/components/shared/lists/ListSaveButton.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'

export default {
  components: {
    NumericInput,
    DetailList,
    ListButtonWrapper,
    ListButton,
    ListEditButton,
    ListSaveButton,
    ListDeleteButton,
    ButtonWrapper,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    sustainabilityYears: {
      type: Array,
    },
  },

  data() {
    return {
      editYear: null,
      yearModel: null,
      addingYear: false,
      pending: false,
      error: null,
      isValid: null,
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    invalidValues() {
      return this.sustainabilityYears.filter((y) => !this.editYear || y.id !== this.editYear.id).map((y) => y.year)
    },

    minYear() {
      return this.portfolio.sim_start_year
    },

    maxYear() {
      return this.portfolio.sim_end_year
    },
  },

  methods: {
    //
    ...mapActions({
      addYear: 'sustainabilityYears/addSustainabilityYear',
      updateYear: 'sustainabilityYears/updateSustainabilityYear',
      deleteYear: 'sustainabilityYears/deleteSustainabilityYear',
      refresh: 'sustainabilityYears/loadSustainabilityYearsByPortfolioId',
    }),

    //
    onAddYear() {
      this.editYear = null
      this.yearModel = null
      this.addingYear = true
      this.error = null
      this.isValid = false
    },

    //
    onAddCancel() {
      this.addingYear = false
    },

    //
    async onAddSave() {
      this.pending = true
      try {
        this.error = null
        if (this.yearModel < this.minYear || this.yearModel > this.maxYear) {
          throw Error(this.$t('yearInvalidError', { minYear: this.minYear, maxYear: this.maxYear }))
        }
        await this.addYear({
          sustainabilityYear: { year: this.yearModel },
          portfolioId: this.portfolio.id,
        })
      } catch (error) {
        this.error = error
      }
      await this.refresh(this.portfolio.id)
      this.pending = false
      this.addingYear = false
    },

    //
    onEditYear(year) {
      this.yearModel = year.year
      this.editYear = year
      this.addingYear = false
      this.isValid = true
    },

    //
    onEditCancel() {
      this.editYear = null
    },

    //
    async onEditSave() {
      try {
        this.error = null
        this.pending = true
        await this.updateYear({
          id: this.editYear.id,
          sustainabilityYear: { year: this.yearModel },
          portfolioId: this.portfolio.id,
        })
      } catch (error) {
        this.error = error
      }
      await this.refresh(this.portfolio.id)
      this.editYear = null
      this.pending = false
    },

    //
    async onDeleteYear(year) {
      const confirmationText = this.$t('deleteConfirmation')
      if (confirm(confirmationText)) {
        try {
          this.error = null
          this.pending = true
          await this.deleteYear({ id: year.id, portfolioId: this.portfolio.id })
        } catch (error) {
          this.error = error
        }
        await this.refresh(this.portfolio.id)
        this.pending = false
      }
    },

    onValidation(valid) {
      this.isValid = valid
    },
  },
}
</script>

<style lang="scss">
.edit-sustainability-years {
  /* & .years-table.detail-list > ul > li {
    grid-template-columns: 1fr 120px;
  } */

  & .row--year {
    min-height: 60px;
  }

  & .add-year-button {
    min-height: 36px;
  }
}
</style>
