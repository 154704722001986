<i18n>
{
  "de": {
    "editTitle": "Energieträger bearbeiten",
    "createTitle": "Energieträger erstellen"
  }
}
</i18n>

<template>
  <Modal class="c-edit-energy-carrier-modal" @close="$emit('close', $event)">
    <div v-if="error">
      <p>{{ error }}</p>
    </div>

    <template #header>
      <h2 v-if="!energyCarrier">{{ $t('createTitle') }}</h2>
      <h2 v-else>{{ $t('editTitle') }}</h2>
    </template>

    <EditEnergyCarrier
      :portfolio="portfolio"
      :reidaOptions="reidaOptions"
      :energy-carrier="energyCarrier"
      :factors="factors"
      :year="year"
      @save="onSave()"
      @cancel="onCancel()"
      @close="onClose()"
      @error="onError()"
    />
  </Modal>
</template>

<script>
import EditEnergyCarrier from '@/components/settings/energy_carriers/EditEnergyCarrier.vue'
import Modal from '@/components/shared/Modal.vue'

export default {
  components: {
    Modal,
    EditEnergyCarrier,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    energyCarrier: {
      type: Object,
    },
    reidaOptions: {
      type: Array,
      required: true,
    },
    factors: {
      type: Object,
    },
    year: {
      type: Object,
    },
  },

  data() {
    return {
      error: null,
    }
  },

  methods: {
    //
    onClose() {
      this.$emit('close')
    },

    //
    onCancel() {
      if (this.energyCarrier === null) {
        this.$emit('close')
      }
    },

    //
    onSave() {
      if (this.energyCarrier === null) {
        if (!this.error) {
          this.$emit('close')
        }
      }
    },

    //
    onError(error) {
      this.error = error
    },
  },
}
</script>
