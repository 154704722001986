<i18n>
{
  "de": {
    "parameterHeader": "Parameter",
    "startYearLabel": "Startjahr",
    "endYearLabel": "Endjahr",
    "interestRate": "Zinssatz",
    "usageTypesStandard": "Nutzungsdatennorm"
  }
}
</i18n>

<template>
  <EditContainer :bind-form-state="formState" class="edit-settings-general" @container="onFormEvent">
    <DetailList has-header class="general-settings-table">
      <template #header>
        <span>{{ $t('parameterHeader') }}</span>
        <span>-</span>
      </template>
      <li>
        <div>{{ $t('startYearLabel') }}</div>
        <div>
          <NumericInput
            v-model="model.sim_start_year"
            noFormatting
            :min="1900"
            :max="2100"
            :edit="isEditing"
            @validation="onValidation($event, 'start_year')"
          />
        </div>
      </li>
      <li>
        <div>{{ $t('endYearLabel') }}</div>
        <div>
          <NumericInput
            v-model="model.sim_end_year"
            noFormatting
            :min="1900"
            :max="2100"
            :edit="isEditing"
            @validation="onValidation($event, 'end_year')"
          />
        </div>
      </li>
      <li>
        <div>{{ $t('interestRate') }}</div>
        <div>
          <NumericInput
            v-model="model.interest_rate"
            :edit="isEditing"
            :units="'%'"
            @validation="onValidation($event, 'interest_rate')"
          />
        </div>
      </li>
      <li>
        <div>{{ $t('usageTypesStandard') }}</div>
        <div>
          <v-select
            v-if="isEditing"
            v-model="model.usage_types_standard"
            :options="usageTypesStandardOptions"
            :clearable="false"
            :reduce="(t) => t"
          />
          <span v-else>{{ model.usage_types_standard }}</span>
        </div>
      </li>
    </DetailList>

    <div v-if="error">
      {{ error }}
    </div>

    <div v-else-if="pending">
      {{ $t('pendingMessage') }}
    </div>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'
import EditContainer from '@/components/shared/forms/EditContainer.vue'

import DetailList from '@/components/shared/lists/DetailList.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    DetailList,
    NumericInput,
    EditContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pending: false,
      error: null,
      usageTypesStandardOptions: ['SIA 2024:2021', 'SIA 2024:2015'],
    }
  },

  watch: {
    portfolio() {
      this.resetModel()
    },
  },

  methods: {
    ...mapActions({
      updateParamsSane: 'portfolio/updateParamsSane',
    }),

    resetModel() {
      this.model = JSON.parse(
        JSON.stringify({
          sim_start_year: this.portfolio.sim_start_year,
          sim_end_year: this.portfolio.sim_end_year,
          usage_types_standard: this.portfolio.usage_types_standard,
          interest_rate: this.portfolio.interest_rate,
        })
      )
    },

    async saveHandler() {
      await this.updateParamsSane({
        id: this.portfolio.id,
        params: this.model,
      })
    },
  },
}
</script>

<style>
.edit-settings-general {
  max-width: 600px;
}
</style>
