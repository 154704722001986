<i18n>
{
  "de": {
    "initial": "Initiale",
    "valuesForUsage": "Werte:",
    "nameTitle": "Energieträger",
    "selectEnergyCarrier": "Energieträger wählen",
    "usageTitle": "Nutzung",
    "selectUsage": "Nutzung wählen",
    "allUsagesDefined": "Alle Nutzungen bereits definiert",
    "inherited": "Vererbt:",
    "negativePEF": "Fossile Primärenergie darf nicht mehr als PEF sein",
    "factorTitle": {
      "s1e": "Scope 1 EF",
      "s2e": "Scope 2 EF",
      "s3e": "Scope 3 EF",
      "s12e": "Scope 1-2 EF",
      "s123e": "Scope 1-3 EF",
      "pe": "Primärenergie (PEF)",
      "per": "PEF Erneuerbar",
      "penr": "PEF Fossil"
    },
    "factorUnits": {
      "s1e": "kg CO₂e/kWh",
      "s2e": "kg CO₂e/kWh",
      "s3e": "kg CO₂e/kWh",
      "s12e": "kg CO₂e/kWh",
      "s123e": "kg CO₂e/kWh",
      "pe": "kWh/kWh",
      "per": "kWh/kWh",
      "penr": "kWh/kWh"
    },
    "usages": {
      "STANDARD": "Standard",
      "TENANTS": "Mieter",
      "FEED_IN": "Rückspeisung"
    }
  }
}
</i18n>

<template>
  <div>
    <EditContainer :bind-form-state="formState" class="c-edit-sustainability-usage" @container="onFormEvent">
      <!-- Name -->
      <FormRow :label="$t('nameTitle')">
        <div v-if="energyCarrier && energyCarrier.default">
          {{ $t(`_energyCarriers.${energyCarrier.name}`) }}
        </div>
        <div v-else-if="energyCarrier && !energyCarrier.default">
          {{ energyCarrier.name }}
        </div>
        <EnergyCarrierPicker
          v-else
          v-model="model.energy_carrier_id"
          :exclude="carriersToExclude"
          :portfolio="portfolio"
          :placeholder="$t('selectEnergyCarrier')"
          edit
        />
      </FormRow>

      <!-- Usage -->
      <FormRow :label="$t('usageTitle')">
        <div v-if="factors">
          {{ $t(`usages.${factors.usage}`) }}
        </div>
        <v-select
          v-else
          v-model="model.usage"
          :clearable="false"
          :options="availableUsages"
          :reduce="(t) => t.id"
          :placeholder="$t('selectUsage')"
        >
          <span slot="no-options">
            {{ $t('allUsagesDefined') }}
          </span>
        </v-select>
      </FormRow>

      <!-- Factors -->
      <template v-if="year">
        <hr />

        <!-- Year -->
        <div class="year">
          {{
            `${year.year === 0 ? $t('initial') : year.year} ${$t('valuesForUsage', {
              usage: model.usage ? $t(`usages.${model.usage}`) : '...',
            })}`
          }}
        </div>

        <!-- Default sustainability indicators -->
        <template v-for="(indicator, index) in sustainabilityIndicators">
          <!-- Default (s1e, s2e, etc.) -->
          <FormRow
            v-if="(internalFactors || isEditing) && !inferredSustainabilityIndicatorIdentifiers.includes(indicator)"
            :key="index"
            :label="`${$t(`factorTitle.${indicator}`)}`"
            :sublabel="`${$t(`factorUnits.${indicator}`)}`"
          >
            <div class="sustainability-indicator">
              <NumericInput
                v-if="isEditing || internalFactors[indicator].manual !== null"
                v-model="model[indicator]"
                no-formatting
                float
                optional
                :edit="isEditing"
                @validation="onValidation($event, indicator)"
              />

              <template v-if="internalFactors && internalFactors[indicator].manual === null">
                <div class="inherited">
                  <span v-if="isEditing"> {{ $t('inherited') }} </span>
                  <span> {{ internalFactors[indicator].inherited }} </span>
                </div>
              </template>
            </div>
          </FormRow>

          <!-- Inferred (s12e, s123e, per) -->
          <FormRow
            v-if="inferredSustainabilityIndicatorIdentifiers.includes(indicator)"
            :key="index"
            :label="`${$t(`factorTitle.${indicator}`)}`"
            :sublabel="`${$t(`factorUnits.${indicator}`)}`"
          >
            <div class="sustainability-indicator">
              <NumericInput
                :value="+inferredSustainabilityIndicatorFactors[indicator].toFixed(10) || 0"
                no-formatting
                float
                optional
                :edit="false"
                class="inherited"
              />
            </div>
            <span v-if="indicator === 'per' && inferredPer < 0">
              {{ $t('negativePEF') }}
            </span>
          </FormRow>
        </template>
      </template>
    </EditContainer>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import NumericInput from '@/components/shared/forms/NumericInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import EnergyCarrierPicker from '@/components/shared/EnergyCarrierPicker.vue'
import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'
import EditContainer from '@/components/shared/forms/EditContainer.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EnergyCarrierPicker,
    NumericInput,
    EditContainer,
    FormRow,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    allEnergyCarriers: {
      type: Array,
    },
    energyCarrier: {
      type: Object,
    },
    allFactors: {
      type: Array,
    },
    factors: {
      type: Object,
    },
    year: {
      type: Object,
    },
  },

  data() {
    return {
      internalFactors: null,
      allPossibleUsages: [
        {
          id: 'STANDARD',
          label: this.$t('usages.STANDARD'),
        },
        {
          id: 'TENANTS',
          label: this.$t('usages.TENANTS'),
        },
        {
          id: 'FEED_IN',
          label: this.$t('usages.FEED_IN'),
        },
      ],
      inferredSustainabilityIndicatorIdentifiers: [
        //
        's12e',
        's123e',
        'per',
      ],
    }
  },

  computed: {
    ...mapState('portfolio', ['defaultSustainabilityIndicatorIdentifiers']),

    ...mapGetters({
      __sustainabilityIndicators: 'portfolio/getSustainabilityIndicators',
    }),

    sustainabilityIndicators() {
      return this.defaultSustainabilityIndicatorIdentifiers.filter((si) => si !== 'ee')
    },

    factorUnits() {
      return this.__sustainabilityIndicators().reduce((obj, si) => ({ ...obj, [si.identifier]: si?.unit }), {})
    },

    inferredS12e() {
      return (
        (this.model.s1e || this.internalFactors?.s1e?.inherited || 0) +
        (this.model.s2e || this.internalFactors?.s2e?.inherited || 0)
      )
    },

    inferredS123e() {
      return (
        (this.model.s1e || this.internalFactors?.s1e?.inherited || 0) +
        (this.model.s2e || this.internalFactors?.s2e?.inherited || 0) +
        (this.model.s3e || this.internalFactors?.s3e?.inherited || 0)
      )
    },

    inferredPer() {
      return (this.model.pe || 0) - (this.model.penr || 0)
    },

    // Inferred factors are treated as as read-only and separately from the rest of the model
    inferredSustainabilityIndicatorFactors() {
      return {
        s12e: this.inferredS12e,
        s123e: this.inferredS123e,
        per: this.inferredPer,
      }
    },

    selectedEnergyCarrierName() {
      if (this.model) {
        const first = this.portfolio.energy_carriers.find((ec) => ec.id === this.model.energy_carrier_id)
        return first?.name
      } else {
        return null
      }
    },

    carriersToExclude() {
      const exclude = ['ELECTRICITY_DYNAMIC', 'ELECTRICITY_DYNAMIC_WITH_PV']
      this.allEnergyCarriers.map((ec) => {
        const availableUsagesOptions = this.allPossibleUsages.filter(
          (usage) =>
            !this.allFactors
              .filter((e) => e.ec_name === ec.name)
              .map((e) => e.usage)
              .includes(usage.id)
        )
        // Exlcude energy carriers that already have all usages defined
        if (availableUsagesOptions.length === 0) {
          exclude.push(ec.name)
        }
        // Exclude energy carriers that aren't electricity grids
        if (!ec.electricity_grid) {
          exclude.push(ec.name)
        }
      })
      return exclude
    },

    availableUsages() {
      if (this.allFactors) {
        return this.allPossibleUsages.filter(
          (usage) =>
            !this.allFactors
              .filter((e) => e.ec_name === this.selectedEnergyCarrierName)
              .map((e) => e.usage)
              .includes(usage.id)
        )
      } else {
        return []
      }
    },

    // Overrides mixin function
    isValid() {
      return (
        // edit
        (this.noInvalidFields &&
          this.selectedEnergyCarrierName &&
          this.factors &&
          this.model.usage &&
          this.inferredPer >= 0) ||
        // new
        (this.noInvalidFields &&
          this.selectedEnergyCarrierName &&
          !this.factors &&
          this.model.usage &&
          this.inferredPer >= 0)
        // && this.modelHasOneSetValue
      )
    },

    modelHasOneSetValue() {
      for (const indicator of this.sustainabilityIndicators) {
        if (this.model[indicator] && this.model[indicator] !== undefined && this.model[indicator] !== null) {
          return true
        }
      }
      return false
    },
  },

  watch: {
    selectedEnergyCarrierName() {
      if (this.isEditing) {
        this.resetModel()
      }
    },
  },

  created() {
    if (!this.energyCarrier || !this.factors) {
      this.onFormEvent('edit')
    }
    if (this.factors) {
      this.internalFactors = this.factors.factors
    }
    this.resetModel()
  },

  methods: {
    //
    ...mapActions({
      update: 'sustainabilityIndicatorFactors/update',
    }),

    //
    stripInheritedAndFlatten(factors) {
      const result = {}
      for (const key in factors) {
        if (!this.inferredSustainabilityIndicatorIdentifiers.includes(key)) {
          result[key] = factors[key].manual
        }
      }
      return result
    },

    //
    inverseStripInheritedAndFlatten(model, factors) {
      const result = { ...factors }
      for (const key in result) {
        if (this.sustainabilityIndicators.includes(key)) {
          result[key].manual = model[key]
        }
      }
      return result
    },

    //
    resetModel() {
      if (this.energyCarrier && this.factors) {
        this.model = {
          // Sustainability Indicator factors
          ...this.stripInheritedAndFlatten(this.internalFactors),
          energy_carrier_id: this.energyCarrier.id,
          usage: this.factors.usage,
        }
      } else if (this.isEditing && this.selectedEnergyCarrierName) {
        this.model.usage = null
      } else {
        this.model = {
          energy_carrier_id: null,
          usage: null,
        }
      }
    },

    //
    async saveHandler() {
      try {
        if (this.year) {
          await this.update({
            portfolioId: this.portfolio.id,
            yearId: this.year.id,
            sustainabilityIndicatorFactors: [
              {
                energy_carrier_name: this.selectedEnergyCarrierName,
                usage: this.model.usage,
                factors: this.mapFactors(this.model),
              },
            ],
          })
          this.resetModel()
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },

    //
    mapFactors(energyCarrier) {
      const keysToRemove = [
        //
        'energy_carrier_id',
        'usage',
      ]
      // Remove unused keys from energyCarrier object
      const indicators = Object.entries(energyCarrier).reduce((acc, [key, value]) => {
        if (!keysToRemove.includes(key)) {
          acc[key] = value
        }
        return acc
      }, {})
      return Object.entries(indicators).map(([key, value]) => {
        return { i: key, v: value }
      })
    },
  },
}
</script>

<style lang="scss">
.c-edit-sustainability-usage {
  margin: 1rem 0;

  & .two-column-row {
    align-items: start;
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & hr {
    height: 1px;
    background-color: #ddd;
    border: none;
    margin: 1rem 0;
  }

  & .year {
    font-weight: 600;
    color: var(--secondary-highlight-color);
    margin-bottom: 1rem;
  }

  & .sustainability-indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    & .inherited {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }

    & .inherited {
      color: #888 !important;
    }
  }
}
</style>

<style scoped>
.units.inherited {
  width: 120px !important;
}
.numeric-input.inherited {
  width: fit-content !important;
}
</style>
