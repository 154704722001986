<i18n>
{
  "de": {
    "nameTitle": "Energieträger",
    "electricityGridTitle": "Stromprodukt",
    "isElectricityGridLabelChecked": "Ja",
    "isElectricityGridLabelUnchecked": "Nein",
    "unitTitle": "Einheit",
    "caloricValueTitle": "Brennwert",
    "kwhPerUnit": "kWh/Einheit",
    "addEnergyCarrier": "Energieträger hinzufügen",
    "deleteEnergyCarrier": "Energieträger löschen",
    "confirmDelete": "Wollen Sie diesen Energieträger wirklich löschen?",
    "duplicateNameErrorMessage": "Name muss innerhalb des Portfolios einzigartig sein.",
    "reidaIdentifierTitle": "REIDA Äquivalent",
    "selectReidaIdentifier": "REIDA Äquivalent auswählen"
  }
}
</i18n>

<template>
  <div>
    <EditContainer :bind-form-state="formState" class="c-edit-energy-carrier" @container="onFormEvent">
      <!-- Name -->
      <FormRow :label="$t('nameTitle')">
        <div v-if="this.internalEnergyCarrier && this.internalEnergyCarrier.default">
          {{ $t(`_energyCarriers.${internalEnergyCarrier.name}`) }}
        </div>
        <TextInput
          v-else
          v-model="model.name"
          :edit="isEditing"
          :invalidValues="existingEnergyCarriers"
          :invalidValuesErrorMessage="$t('duplicateNameErrorMessage')"
          @validation="onValidation($event, 'name')"
        />
      </FormRow>

      <!-- Energy grid / Stromprodukt: yes / no -->
      <FormRow :label="$t('electricityGridTitle')">
        <CheckInput
          v-if="!this.internalEnergyCarrier || !(this.internalEnergyCarrier && this.internalEnergyCarrier.default)"
          v-model="model.electricity_grid"
          :edit="isEditing"
        />
        <span v-if="(this.internalEnergyCarrier && this.internalEnergyCarrier.default) || !isEditing">
          {{ model.electricity_grid ? $t('isElectricityGridLabelChecked') : $t('isElectricityGridLabelUnchecked') }}
        </span>
      </FormRow>

      <!-- Units -->
      <FormRow :label="$t('unitTitle')">
        <TextInput
          v-model="model.unit"
          :edit="!(this.internalEnergyCarrier && this.internalEnergyCarrier.default) && isEditing"
        />
      </FormRow>

      <!-- Caloric value / Brennwert -->
      <FormRow :label="$t('caloricValueTitle')">
        <NumericInput
          v-model="model.caloric_value"
          no-formatting
          float
          :edit="isEditing"
          @validation="onValidation($event, 'caloric_value')"
        />
      </FormRow>

      <!-- REIDA identifier -->
      <FormRow :label="$t('reidaIdentifierTitle')">
        <v-select
          v-if="isEditing"
          v-model="model.reida_identifier"
          :clearable="false"
          :options="reidaSelectOptions"
          :reduce="(t) => t.id"
          :placeholder="$t('selectReidaIdentifier')"
        />
        <span v-else>{{ selectedLabel }}</span>
      </FormRow>
    </EditContainer>

    <template v-if="internalEnergyCarrier && !internalEnergyCarrier.default">
      <button type="button" class="button" @click="onDelete(internalEnergyCarrier)">
        {{ $t('deleteEnergyCarrier') }}
      </button>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import _ from 'lodash'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'
import EditContainer from '@/components/shared/forms/EditContainer.vue'

import CheckInput from '@/components/shared/forms/CheckInput.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    TextInput,
    CheckInput,
    NumericInput,
    EditContainer,
    FormRow,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    energyCarrier: {
      type: Object,
    },
    reidaOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      internalEnergyCarrier: null,
    }
  },

  computed: {
    ...mapState('portfolio', ['defaultSustainabilityIndicatorIdentifiers']),

    existingEnergyCarriers() {
      if (this.internalEnergyCarrier && this.internalEnergyCarrier.id) {
        // if editing an existing energy carrier, exclude it from the list of invalid values
        return this.portfolio.energy_carriers
          .filter((ec) => ec.id !== this.internalEnergyCarrier.id)
          .map((ec) => ec.name)
      } else {
        // if creating a new energy carrier, include all existing energy carriers in the list of invalid values
        return this.portfolio.energy_carriers.filter((ec) => ec.id).map((ec) => ec.name)
      }
    },

    reidaSelectOptions() {
      return this.reidaOptions.map((o) => ({ label: o.name, id: o.id }))
    },

    selectedLabel() {
      const selected = this.reidaOptions.find((o) => o.id === this.model.reida_identifier)
      return selected === undefined ? '-' : selected.name
    },
  },

  watch: {
    energyCarrier() {
      this.internalEnergyCarrier = _.cloneDeep(this.energyCarrier)
      this.resetModel()
    },
  },

  created() {
    if (this.energyCarrier) {
      this.internalEnergyCarrier = _.cloneDeep(this.energyCarrier)
    }
    if (!this.energyCarrier) {
      this.onFormEvent('edit')
    }
    this.resetModel()
  },

  methods: {
    //
    ...mapActions({
      updateEnergyCarrier: 'portfolio/updateEnergyCarrier',
      addEnergyCarrier: 'portfolio/addEnergyCarrier',
      deleteEnergyCarrier: 'portfolio/deleteEnergyCarrier',
      refreshDetailsById: 'portfolio/refreshDetailsById',
    }),

    //
    resetModel() {
      if (this.internalEnergyCarrier) {
        this.model = {
          // Energy Carrier basic fields
          portfolio_id: this.internalEnergyCarrier.portfolio_id,
          name: this.internalEnergyCarrier.name,
          electricity_grid: this.internalEnergyCarrier.electricity_grid,
          unit: this.internalEnergyCarrier.unit,
          caloric_value: this.internalEnergyCarrier.caloric_value,
          reida_identifier: this.internalEnergyCarrier.reida_identifier,
        }
      } else {
        this.model = {
          // When creating a new Energy Carrier, only set name and other basic fields
          default: false,
          portfolio_id: this.portfolio.id,
          name: '',
          electricity_grid: false,
          unit: 'Unknown',
          caloric_value: 1,
          reida_identifier: '',
        }
      }
    },

    //
    async saveHandler() {
      try {
        if (this.internalEnergyCarrier) {
          await this.updateEnergyCarrier({
            id: this.internalEnergyCarrier.id,
            energyCarrier: {
              portfolio_id: this.portfolio.id,
              name: this.model.name,
              unit: this.model.unit,
              electricity_grid: this.model.electricity_grid,
              caloric_value: this.model.caloric_value,
              reida_identifier: this.model.reida_identifier,
            },
          })
          this.internalEnergyCarrier = { ...this.internalEnergyCarrier, ...this.model }
        } else {
          await this.addEnergyCarrier({
            portfolio_id: this.portfolio.id,
            name: this.model.name,
            unit: this.model.unit,
            electricity_grid: this.model.electricity_grid,
            caloric_value: this.model.caloric_value,
            reida_identifier: this.model.reida_identifier,
          })
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },

    //
    async onDelete(energyCarrier) {
      if (!energyCarrier.default) {
        if (confirm(this.$t('confirmDelete'))) {
          try {
            this.error = null
            this.pending = true
            await this.deleteEnergyCarrier({ id: energyCarrier.id, portfolioId: energyCarrier.portfolio_id })
          } catch (error) {
            this.error = error
          }
          this.pending = false
          this.$emit('close')
        }
      }
    },

    selectReidaIdentifier(identifier) {
      this.model.reida_identifier = identifier
      this.$emit('input', identifier)
    },
  },
}
</script>

<style lang="scss">
.c-edit-energy-carrier {
  margin: 1rem 0;

  & .two-column-row {
    align-items: center;
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & hr {
    height: 1px;
    background-color: #ddd;
    border: none;
    margin: 1rem 0;
  }

  & .reida-energy-carrier-dropdown > a {
    padding: var(--spacing-s);
    border: var(--box-border);
    border-radius: var(--box-radius);
  }

  & .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xs);
  }

  /* & .year {
    font-weight: 600;
    color: var(--secondary-highlight-color);
    margin-bottom: 1rem;
  }

  & .sustainability-indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  & .inherited {
    color: #888;
  } */
}
</style>
