<i18n>
{
  "de": {
    "editTitle": "Nutzung bearbeiten",
    "createTitle": "Nutzung erstellen"

  }
}
</i18n>

<template>
  <Modal class="edit-sustainability-usage-modal" @close="$emit('close', $event)">
    <div v-if="error">
      <p>{{ error }}</p>
    </div>

    <template #header>
      <h2 v-if="!factors || !energyCarrier">{{ $t('createTitle') }}</h2>
      <h2 v-else>{{ $t('editTitle') }}</h2>
    </template>

    <EditSustainabilityUsage
      :portfolio="portfolio"
      :all-energy-carriers="allEnergyCarriers"
      :energy-carrier="energyCarrier"
      :all-factors="allFactors"
      :factors="factors"
      :year="year"
      @save="onSave()"
      @cancel="onCancel()"
      @close="onClose()"
      @error="onError()"
    />
  </Modal>
</template>

<script>
import EditSustainabilityUsage from '@/components/settings/energy_carriers/EditSustainabilityUsage.vue'
import Modal from '@/components/shared/Modal.vue'

export default {
  components: {
    Modal,
    EditSustainabilityUsage,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    allEnergyCarriers: {
      type: Array,
    },
    energyCarrier: {
      type: Object,
    },
    allFactors: {
      type: Array,
    },
    factors: {
      type: Object,
    },
    year: {
      type: Object,
    },
  },

  data() {
    return {
      error: null,
    }
  },

  methods: {
    //
    onClose() {
      this.$emit('close')
    },

    //
    onCancel() {
      if (this.energyCarrier === null) {
        this.$emit('close')
      }
    },

    //
    onSave() {
      if (!this.error) {
        this.$emit('close')
      }
    },

    //
    onError(error) {
      this.error = error
    },
  },
}
</script>
